import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class Badges extends Component {
    render() {
        let title = 'Badges';
        let code = `
    <ul class="nav nav-pills">
        <li class="active"><a href="#">Home <span class="badge">42</span></a></li>
        <li><a href="javascript:void(0);">Profile <span class="badge"></span></a></li>
        <li class="active"><a href="javascript:void(0);">Messages <span class="badge">3</span></a></li>
    </ul>
            `;


        return (
            <div className="col-lg-4">
                <h3 className="header-primary">
                    {title}
                    <CodeModal code={code} title={title} />
                </h3>
                <div>{ReactHtmlParser(code)}</div>
            </div>
        )
    }
}

export default Badges;