import React, { Component } from 'react';
import BasicPage from './BasicPage';

class Pages extends Component {
    render() {
        return (
            <div className="page-float">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-header">
                            <h1 id="pages" className="header-warning">Pages/Layout</h1>
                        </div>
                    </div>
                </div>
                <BasicPage />
            </div>
        )
    }
}

export default Pages;