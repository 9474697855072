import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class OtherElements extends Component {
    render() {
        let title = 'Other Elements';
        let code = `
    <div>
        <div class="col-lg-4">
            <div class="form-group form-object-uploader">
                <label class="control-label">Uploader</label>
                <div>
                    <div id="widgetLink" role="button">
                        <section class="reach-image-selector">
                            <button type="button" class="glyphicon glyphicon-remove-circle btn-link">
                            </button>
                            <div class="drop-box">
                                <div role="button" tabindex="0">
                                    <div class="preview">
                                        <img src="images/Tulips.jpg" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-group">
                <label class="control-label">Switch</label>
                <div class="input-group input-switch">
                    <div class="switch-primary switch-large ats-switch" role="switch" id="fillerToggle"
                        html="true" tabindex="0">
                        <div class="switch-animate switch-on"> <span class="switch-left">On</span> <span class="knob"></span>
                            <span class="switch-right">Off</span> </div>
                    </div>
                </div>
                <div class="input-group input-switch">
                    <div class="switch-primary switch-large ats-switch" role="switch" id="fillerToggle"
                        html="true" tabindex="0">
                        <div class="switch-animate switch-off"> <span class="switch-left">On</span> <span class="knob"></span>
                            <span class="switch-right">Off</span> </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-group">
                <label class="control-label">Sliders</label>
                <div class="input-group input-slider">
                    <div class="slider slider-horizontal" id="slider2">
                        <div class="slider-track">
                            <div class="slider-track-low"></div>
                            <div class="slider-selection"></div>
                            <div class="slider-track-high"></div>
                            <div class="slider-handle min-slider-handle round" role="slider" tabindex="0"></div>
                            <div class="slider-handle max-slider-handle round hide" role="slider" tabindex="0"></div>
                        </div>
                        <div class="tooltip tooltip-main top hide" role="presentation">
                            <div class="tooltip-arrow"></div>
                            <div class="tooltip-inner">X: -35</div>
                        </div>
                        <div class="tooltip tooltip-min top hide" role="presentation">
                            <div class="tooltip-arrow"></div>
                            <div class="tooltip-inner"></div>
                        </div>
                        <div class="tooltip tooltip-max hide top" role="presentation">
                            <div class="tooltip-arrow"></div>
                            <div class="tooltip-inner"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
            `;


        return (
            <div className="col-lg-12">
                <h3 className="header-primary">
                    {title}
                    <CodeModal code={code} title={title} />
                </h3>
                <div>{ReactHtmlParser(code)}</div>
            </div>
        )
    }
}

export default OtherElements;