import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class BasicPage extends Component {
    render() {
        let title = 'Basic Page Layout';
        let code = `
        <div class="page-layout facilities-page">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-8">
                    <h3 class="header-primary">Application Title</h3>
                </div>
                <div class="col-md-4 header-row">
                    <div class="input-group">
                        <input ng-model="searchQuery" id="facility-search" ng-model-options="{ debounce: 250 }" ng-change="updateSearch(searchQuery)"
                            class="form-control header-search" placeholder="Search facilities..." />
                        <span class="input-group-addon">
                            <i class="glyphicon glyphicon-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <ul class="breadcrumb">
                        <li><a href="#!/">Previous Page</a></li>
                        <li class="active">Current Page</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="page-float">
                        <div class="row">
                            <div class="col-md-12">
                                <h2>Page content goes here</h2>
                                <p>The entire page float must show in the window at all times. If the content overflows,
                                the page float should scroll vertically.</p>
                                <p>Breadcrumbs are not used on pages directly accessed by the navigation sidebar.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>`;

        return (
            <div className="row">
                <div className="col-lg-12">
                    <h3 className="header-primary">
                        {title}
                        <CodeModal code={code} title={title} />
                    </h3>
                    <div>
                        { ReactHtmlParser(code) }
                     </div>
                </div>
            </div>
        )
    }
}

export default BasicPage;