import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class Alerts extends Component {
    render() {
        let title = 'Alerts';
        let code = `
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="alert alert-dismissible alert-warning">
                    <button type="button" class="close" data-dismiss="alert">&times;</button>
                    <h4>Warning!</h4>
                    <p>Best check yo self, you're not looking too good. Nulla vitae elit libero, a pharetra augue. Praesent
                    commodo cursus magna, <a href="#" class="alert-link">vel scelerisque nisl consectetur et</a>.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="alert alert-dismissible alert-danger">
                    <button type="button" class="close" data-dismiss="alert">&times;</button>
                    <strong>Oh snap!</strong> <a href="#" class="alert-link">Change a few things up</a> and try submitting
                    again. </div>
            </div>
            <div class="col-lg-4">
                <div class="alert alert-dismissible alert-success">
                    <button type="button" class="close" data-dismiss="alert">&times;</button>
                    <strong>Well done!</strong> You successfully read <a href="#" class="alert-link">this important alert
                    message</a>. </div>
            </div>
            <div class="col-lg-4">
                <div class="alert alert-dismissible alert-info">
                    <button type="button" class="close" data-dismiss="alert">&times;</button>
                    <strong>Heads up!</strong> This <a href="#" class="alert-link">alert needs your attention</a>, but it's
                    not super important. </div>
            </div>
        </div>
    </div>
            `;


        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="header-primary">
                            {title}
                            <CodeModal code={code} title={title} />
                        </h3>
                    </div>
                </div>
                <div>{ReactHtmlParser(code)}</div>
            </div>
        )
    }
}

export default Alerts;