import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class Wells extends Component {
    render() {
        let title = 'Wells';
        let code = `
    <div class="row">
        <div class="col-lg-4">
            <div class="well"> Look, I'm in a well! </div>
        </div>
        <div class="col-lg-4">
            <div class="well well-sm"> Look, I'm in a small well! </div>
        </div>
        <div class="col-lg-4">
            <div class="well well-lg"> Look, I'm in a large well! </div>
        </div>
    </div>
            `;


        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="header-primary">
                            {title}
                            <CodeModal code={code} title={title} />
                        </h3>
                    </div>
                </div>
                <div>{ ReactHtmlParser(code) }</div>
            </div>
        )
    }
}

export default Wells;