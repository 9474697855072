import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class BasicForm extends Component {
    render() {
        let title = 'Basic Form';
        let code = `
    <div class="well">
        <div class="row form-group">
            <div class="col-md-10 col-md-offset-1">
                <label for="inputEmail">Email:</label>
                <input type="text" class="form-control" id="inputEmail" placeholder="Email" />
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-10 col-md-offset-1">
                <label for="inputPassword" >Password:</label>
                <input type="password" class="form-control" id="inputPassword" placeholder="Password" />
                <div class="checkbox">
                    <label>
                        <input type="checkbox" />
                        Checkbox
                    </label>
                </div>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-10 col-md-offset-1">
                <label for="textArea">Textarea:</label>
                <textarea class="form-control" rows="3" id="textArea"></textarea>
                <span class="help-block">
                    A longer block of help text that breaks onto a new line and may extend
                    beyond one line.
                        </span>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-10 col-md-offset-1">
                <label >Radios:</label>
                <div class="radio">
                    <label>
                        <input type="radio" name="optionsRadios" id="optionsRadios1" value="option1" checked="" />
                        Option one is this </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio" name="optionsRadios" id="optionsRadios2" value="option2" />
                        Option two can be something else </label>
                </div>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-10 col-md-offset-1">
                <label for="select">Selects:</label>
                <select class="form-control" id="select">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                </select>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-lg-4 col-lg-offset-8 form-buttons">
                <button type="reset" class="btn btn-default">Cancel</button>
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </div>
    </div>
            `;


        return (
            <div className="col-lg-6 basic-form">
                <h3 className="header-primary">
                    {title}
                    <CodeModal code={code} title={title} />
                </h3>
                <div>
                    {ReactHtmlParser(code)}
                </div>
            </div>
        )
    }
}

export default BasicForm;