import React, { Component } from 'react';
import BasicForm from './BasicForm';
import Inputs from './Inputs';
import OtherElements from './OtherElements';

class Forms extends Component {
    render() {
        return (
            <div className="page-float">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-header">
                            <h1 id="forms" className="header-warning">Forms</h1>
                            <p className="lead">Forms make up much of the CMS, and it is important to keep the look and feel consistent.
                            The styles in place should make this easily accomplished by simply using the correct html elements and classes.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <BasicForm />
                    <Inputs />
                </div>
                <div className="row">
                    <OtherElements />
                </div>
            </div>
        )
    }
}

export default Forms;