import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class Navbar extends Component {
    render() {
        let title = 'Left Navbar';
        let code = `
    <div class="sidebar-inner">
        <section class="sidebar-menu-item sidebar-menu-item-selected">
            <span class="fa fa-edit nav-icon"></span> Layout Editor
        </section>
        <section class="sidebar-menu-item">
            <span class="fa fa-laptop nav-icon"></span> Players
        </section>
        <section class="sidebar-menu-item">
            <span class="fa fa-microphone nav-icon"></span> Announcements
        </section>
        <section class="sidebar-menu-item">
            <span class="fa fa-calendar nav-icon"></span> Calendar
        </section>
        <section class="sidebar-menu-item">
            <span class="fa fa-tasks nav-icon"></span> Data Lists
        </section>
    </div>
            `;


        return (
            <div className="col-md-4 col-md-offset-2 navigation-left-menu">
                <h3 className="header-primary">
                    {title}
                    <CodeModal code={code} title={title} />
                </h3>
                <div>{ReactHtmlParser(code)}</div>
            </div>
        )
    }
}

export default Navbar;