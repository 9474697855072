import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class ButtonsDisabled extends Component {
    render() {
        let title = 'Disabled Buttons';
        let code = `
            <div>
                <button href="#" class="btn btn-default disabled">Default</button>
                <button href="#" class="btn btn-primary disabled">Primary</button>
                <button href="#" class="btn btn-success disabled">Success</button>
                <button href="#" class="btn btn-info disabled">Info</button>
                <button href="#" class="btn btn-warning disabled">Warning</button>
                <button href="#" class="btn btn-danger disabled">Danger</button>
                <button href="#" class="btn btn-link disabled">Link</button>
            </div>`;

        return (
            <div className="row">
                <div className="col-lg-12">
                    <h3 className="header-primary">
                        {title}
                        <CodeModal code={code} title={title} />
                    </h3>
                    <div>{ ReactHtmlParser(code) }</div>
                </div>
            </div>
        )
    }
}

export default ButtonsDisabled;