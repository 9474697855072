import React, { Component } from 'react';
import BasicTable from './BasicTable';
import ListSelector from './ListSelector';

class Tables extends Component {
    render() {
        return (
            <div className="page-float">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-header">
                            <h1 id="tables" className="header-warning">Tables/Grids</h1>
                            <p className="lead">There are many tables in Reach's CMS. Many tables are rendered using ui-grid, and some are rendered using ng-table.
                            Because of this, styles may be difficult to make consistent. Buttons should have no background or border in tables.</p>
                        </div>
                    </div>
                </div>
                <BasicTable />
                <ListSelector />
            </div>
        )
    }
}

export default Tables;