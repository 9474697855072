import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class ListSelector extends Component {
    render() {
        let title = 'List Selector';
        let code = `
    <div>
        <div class="col-lg-5">
            <div class="selector-container">
                <table class="table table-bordered table-hover table-striped">
                    <thead>
                        <tr>
                            <th colspan="100"><strong>Group A</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="name-cell">
                                <div class="ui-grid-cell-contents"> Line One </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="name-cell">
                                <div class="ui-grid-cell-contents"> Line Two </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="name-cell">
                                <div class="ui-grid-cell-contents"> Line Three </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="name-cell">
                                <div class="ui-grid-cell-contents"> Line Four </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="name-cell">
                                <div class="ui-grid-cell-contents"> Line Five </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="name-cell">
                                <div class="ui-grid-cell-contents"> Line Six </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-lg-1">
            <div class="row list-selector-btn">
                <button class="btn btn-primary">
                    <span class="fa fa-chevron-right"></span>
                </button>
            </div>
            <div class="row list-selector-btn">
                <button class="btn btn-primary">
                    <span class="fa fa-chevron-left"></span>
                </button>
            </div>
        </div>
        <div class="col-lg-5">
            <div class="selector-container">
                <table class="table table-bordered table-hover table-striped">
                    <thead>
                        <tr>
                            <th colspan="100"><strong>Group B</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="name-cell">
                                <div class="ui-grid-cell-contents"> Line Seven </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="name-cell">
                                <div class="ui-grid-cell-contents"> Line Eight </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="name-cell">
                                <div class="ui-grid-cell-contents"> Line Nine </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
            `;


        return (
            <div className="row">
                <div className="col-lg-12">
                    <h3 className="header-primary">
                        {title}
                        <CodeModal code={code} title={title} />
                    </h3>
                </div>
                <div className="col-lg-12">
                    <div>{ReactHtmlParser(code)}</div>
                </div>
            </div>
        )
    }
}

export default ListSelector;