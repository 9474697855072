import React, { Component } from 'react';

class BasicTableRows extends Component {
    render() {
        return (
            <tr>
                <td>{this.props.data.number}</td>
                <td>{this.props.data.name}</td>
                <td>{this.props.data.email}</td>
                <td>{this.props.data.phone}</td>
                <td><button className="btn btn-danger">
                    <i className="fa fa-trash-alt"></i>
                </button></td>
            </tr>
        )
    }
}

export default BasicTableRows;