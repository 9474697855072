import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class Labels extends Component {
    render() {
        let title = 'Labels';
        let code = `
    <div> 
        <span class="label label-default">Default</span> 
        <span class="label label-primary">Primary</span> 
        <span class="label label-success">Success</span> 
        <span class="label label-warning">Warning</span> 
        <span class="label label-danger">Danger</span> 
        <span class="label label-info">Info</span>
   </div>
            `;


        return (
            <div className="col-lg-4">
                <h3 className="header-primary">
                    {title}
                    <CodeModal code={code} title={title} />
                </h3>
                <div>{ReactHtmlParser(code)}</div>
            </div>
        )
    }
}

export default Labels;