import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class Body extends Component {
    render() {
        let title = 'Body Text';
        let code = `
            <div>
                <p>Nullam quis risus eget <a href="#">urna mollis ornare</a> vel eu leo. Cum sociis natoque penatibus 
                et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula.</p>
                <p><small>This line of text is meant to be treated as fine print.</small></p>
                <p>The following snippet of text is <strong>rendered as bold text</strong>.</p>
                <p>The following snippet of text is <em>rendered as italicized text</em>.</p>
            </div>
            `;


        return (
            <div className="col-lg-6">
                <h3 className="header-primary">
                    {title}
                    <CodeModal code={code} title={title} />
                </h3>
                <div>{ReactHtmlParser(code)}</div>
            </div>
        );
    }
}

export default Body;