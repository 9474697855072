import React, { Component } from 'react';
import Tabs from './Tabs';
import Breadcrumbs from './Breadcrumbs';
import Pagination from './Pagination';
import Navbar from './Navbar';

class Navs extends Component {
    render() {
        return (
            <div className="page-float">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-header">
                            <h1 id="navs" className="header-warning">Navs</h1>
                            <p className="lead">There are several methods of navigation used in the CMS.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Tabs />
                    <Navbar />
                </div>
                <div className="row">
                    <Pagination />
                    <Breadcrumbs />
                </div>
            </div>
        )
    }
}

export default Navs;