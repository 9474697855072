import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';

class TableCode extends Component {

    render() {
        let title = 'Basic Table';
        let code = `
    <table class="table table-striped table-hover table-bordered">
        <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email Address</th>
                <th>Phone Number</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>John</td>
                <td>john@email.com</td>
                <td>651-123-4567</td>
                <td><button class="btn btn-danger">
                    <i class="fa fa-trash-alt"></i>
                </button></td>
            </tr>
            <tr>
                <td>2</td>
                <td>Mary</td>
                <td>mary@email.com</td>
                <td>651-123-8910</td>
                <td><button class="btn btn-danger">
                    <i class="fa fa-trash-alt"></i>
                </button></td>
            </tr>
            <tr>
                <td>3</td>
                <td>Lisa</td>
                <td>lisa@email.com</td>
                <td>651-123-9876</td>
                <td><button class="btn btn-danger">
                    <i class="fa fa-trash-alt"></i>
                </button></td>
            </tr>
            <tr>
                <td>4</td>
                <td>Austin</td>
                <td>austin@email.com</td>
                <td>651-123-5432</td>
                <td><button class="btn btn-danger">
                    <i class="fa fa-trash-alt"></i>
                </button></td>
            </tr>
            <tr>
                <td>5</td>
                <td>Ella</td>
                <td>ella@email.com</td>
                <td>651-123-1234</td>
                <td><button class="btn btn-danger">
                    <i class="fa fa-trash-alt"></i>
                </button></td>
            </tr>
        </tbody>
    </table>
            `;


        return (
            <span>
                <CodeModal code={code} title={title} />
            </span>
        )
    }
}

export default TableCode;
