import React, { Component } from 'react';
import Headings from './Headings';
import Body from './Body';
import Blockquotes from './Blockquotes';

class Typography extends Component {
    render() {
        return (
            <div className="page-float">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-header">
                            <h1 id="typography" className="header-warning">Typography</h1>
                            <p className="lead">The typography used in Reach's CMS is very straightforward. There is a header class for each of the bootstrap colors.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Headings />
                    <Body />
                </div>
                <div className="row">
                    <Blockquotes />
                </div>
            </div>
        )
    }
}

export default Typography;