import React, { Component } from 'react';
import ButtonsActive from './ButtonsActive';
import ButtonsDisabled from './ButtonsDisabled';
import ButtonsSize from './ButtonsSize';

class Buttons extends Component {
    render() {
        return (
            <div className="page-float buttons">
                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 id="buttons" className="header-warning">Buttons</h1>
                            <p className="lead">Most buttons used in Reach's CMS are primary or default. Primary buttons should be used for affirmative actions, like saving something or opening a modal. 
                                Default buttons are typically used for canceling changes or other actions that require some caution.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <ButtonsActive />
                        <ButtonsDisabled />
                    </div>
                    <div className="col-lg-6">
                        <ButtonsSize />
                    </div>
                </div>
            </div>
        );
    }
}

export default Buttons;
