import React, { Component } from 'react';
import Modals from './Modals';

class Dialogs extends Component {
  render() {
    return (
      <div className="page-float">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-header">
              <h1 id="dialogs" className="header-warning">Dialogs</h1>
              <p className="lead">Modals are another large part of Reach's CMS. By following the simple structure of elements and classes,
              it's easy to achieve a consistent style among modals.</p>
            </div>
          </div>
        </div>
        <Modals />
      </div>
    )
  }
}

export default Dialogs;