import React, { Component } from 'react';
import Buttons from './components/Buttons/Buttons';
import Typography from './components/Typography/Typography';
import Tables from './components/Tables/Tables';
import Forms from './components/Forms/Forms';
import Navs from './components/Navs/Navs';
import Indicators from './components/Indicators/Indicators';
import Containers from './components/Containers/Containers';
import Dialogs from './components/Dialogs/Dialogs';
import Pages from './components/Pages/Pages';
import './App.scss';

class App extends Component {
  render() {
    return (
      <div className="App">
      <h1 className="header-primary main-header">Reach Style Guide</h1>
        <Buttons />
        <Typography />
        <Tables />
        <Forms />
        <Navs />
        <Indicators />
        <Containers />
        <Dialogs />
        <Pages />
      </div>
    );
  }
}

export default App;
