import React, { Component } from 'react';
import BasicTableRows from './BasicTableRows';
import TableCode from './TableCode';

class BasicTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [
                {
                    number: '1',
                    name: 'John',
                    email: 'john@email.com',
                    phone: '651-123-4567'
                },
                {
                    number: '2',
                    name: 'Mary',
                    email: 'mary@email.com',
                    phone: '651-123-8910'
                },
                {
                    number: '3',
                    name: 'Lisa',
                    email: 'lisa@email.com',
                    phone: '651-123-9876'
                },
                {
                    number: '4',
                    name: 'Austin',
                    email: 'austin@email.com',
                    phone: '651-123-5432'
                },
                {
                    number: '5',
                    name: 'Ella',
                    email: 'ella@email.com',
                    phone: '651-123-1234'
                },
            ]
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-12">
                    <h3 className="header-primary basic-table-header">Basic Table</h3>
                    <TableCode />
                </div>
                <div className="col-lg-12">
                    <table className="table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email Address</th>
                                <th>Phone Number</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tableData.map((data) => {
                                return (
                                    <BasicTableRows data={data} key={data.number} />
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default BasicTable;