import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class Modals extends Component {
    render() {
        let title = 'Modals';
        let code = `
    <div class="modal-content">
        <div class="reach-modal-close">
            <button type="button" class="close"> <span>&times;</span> </button>
        </div>
        <div class="modal-header">
            <h3 class="modal-title">Modal Label</h3>
        </div>
        <div class="modal-header-secondary">Secondary Modal Label</div>
        <div class="modal-body"> Modal Body.. </div>
        <div class="modal-footer">
            <button class="btn btn-default" type="submit">Cancel</button>
            <button class="btn btn-primary" type="submit">OK</button>
        </div>
    </div>`;


        return (
            <div className="row">
                <div className="col-lg-6 col-lg-offset-3">
                    <h3 className="header-primary">
                        {title}
                        <CodeModal code={code} title={title} />
                    </h3>
                    <div>{ ReactHtmlParser(code) }</div>
                </div>
            </div>
        )
    }
}

export default Modals;