import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class Navigation extends Component {
    render() {
        let title = 'Steps Indicator';
        let code = `
    <ul class="steps-indicator steps-4">
        <li class="done"> <a>Name</a> </li>
        <li class="current"> <a>Dimensions</a> </li>
        <li class="default"> <a>Duration</a> </li>
        <li class="default"> <a>Position</a> </li>
    </ul>
            `;


        return (
            <div className="col-lg-4 indicator-navigation">
                <h3 className="header-primary">
                    {title}
                    <CodeModal code={code} title={title} />
                </h3>
                <div>{ReactHtmlParser(code)}</div>
            </div>
        )
    }
}

export default Navigation;