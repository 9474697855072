import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class Panels extends Component {
    render() {
        let title = 'Panels';
        let code = `
    <div class="row">
        <div class="col-lg-4">
            <div class="panel panel-default">
                <div class="panel-body"> Basic panel </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading">Panel heading</div>
                <div class="panel-body"> Panel content </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-body"> Panel content </div>
                <div class="panel-footer">Panel footer</div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    <h3 class="panel-title">Panel primary</h3>
                </div>
                <div class="panel-body"> Panel content </div>
            </div>
            <div class="panel panel-success">
                <div class="panel-heading">
                    <h3 class="panel-title">Panel success</h3>
                </div>
                <div class="panel-body"> Panel content </div>
            </div>
            <div class="panel panel-warning">
                <div class="panel-heading">
                    <h3 class="panel-title">Panel warning</h3>
                </div>
                <div class="panel-body"> Panel content </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="panel panel-danger">
                <div class="panel-heading">
                    <h3 class="panel-title">Panel danger</h3>
                </div>
                <div class="panel-body"> Panel content </div>
            </div>
            <div class="panel panel-info">
                <div class="panel-heading">
                    <h3 class="panel-title">Panel info</h3>
                </div>
                <div class="panel-body"> Panel content </div>
            </div>
            <div class="panel panel-default panel-player-card">
                <div class="panel-body">
                    <div class="player-card-left">
                        <div class="inner-div"> <img width="110" height="80" src="https://api.reachcm.com/cdn/asset/e1550e17-12ef-4817-90fa-676645be9f37" />
                        </div>
                    </div>
                    <div class="player-card-right">
                        <div id="player-status-div">
                            <div class="player-status-icon">
                                <div class="dashboard-indicator online-indicator"></div>
                            </div>
                        </div>
                        <div class="online-div">
                            <label>Online</label>
                        </div>

                        <div class="player-info"> <span class="player-info-lbl">Name: </span> <span class="player-info-value"
                            tabindex="0">Calstrs Testing Version 8</span>
                            <span class="player-info-lbl">Building: </span> <span class="player-info-value">default</span>
                            <span class="player-info-lbl">Location: </span> <span class="player-info-value">default</span>
                            <span class="player-info-lbl">Last Online: </span> <span class="player-info-value">Now</span>
                        </div>
                    </div>
                    <div class="ui-grid-selection-row-header-buttons ui-grid-icon-ok" role="button" tabindex="0"><i class="fas fa-check"></i></div>
                </div>
                <div class="panel-footer">
                    <div class="player-card-button"><i class="fas fa-sync-alt"></i></div>
                    <div class="player-card-button"><i class="fas fa-power-off"></i></div>
                    <div class="player-card-button"><i class="fas fa-camera"></i></div>
                </div>
            </div>
        </div>
    </div>
            `;


        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="header-primary">
                            {title}
                            <CodeModal code={code} title={title} />
                        </h3>
                    </div>
                </div>
                <div>{ ReactHtmlParser(code) }</div>
            </div>
        )
    }
}

export default Panels;