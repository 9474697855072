import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class ButtonsActive extends Component {

    render() {
        let title = 'Active Buttons';
        let code = `
            <div>
                <button href="#" class="btn btn-default">Default</button>
                <button href="#" class="btn btn-primary">Primary</button>
                <button href="#" class="btn btn-success">Success</button>
                <button href="#" class="btn btn-info">Info</button>
                <button href="#" class="btn btn-warning">Warning</button>
                <button href="#" class="btn btn-danger">Danger</button>
                <button href="#" class="btn btn-link">Link</button>
            </div>`;

        return (
            <div className="row">
                <div className="col-lg-12">
                    <h3 className="header-primary">
                        {title}
                        <CodeModal code={code} title={title} />
                    </h3>
                    <div>
                        { ReactHtmlParser(code) }
                    </div>
                </div>
            </div>
        )
    }
}

export default ButtonsActive;