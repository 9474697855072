import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class ListGroups extends Component {
    render() {
        let title = 'List Groups';
        let code = `
    <div class="row">
        <div class="col-lg-4">
            <ul class="list-group">
                <li class="list-group-item"> <span class="badge">14</span> Cras justo odio </li>
                <li class="list-group-item"> <span class="badge">2</span> Dapibus ac facilisis in </li>
                <li class="list-group-item"> <span class="badge">1</span> Morbi leo risus </li>
            </ul>
        </div>
        <div class="col-lg-4">
            <div class="list-group"> <a href="#" class="list-group-item active"> Cras justo odio </a> <a href="#" class="list-group-item">Dapibus
  ac facilisis in </a> <a href="#" class="list-group-item">Morbi leo risus </a> </div>
        </div>
        <div class="col-lg-4">
            <div class="list-group"> 
            <a href="#" class="list-group-item">
                <h4 class="list-group-item-heading">List group item heading</h4>
                <p class="list-group-item-text">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam
                eget risus varius blandit.</p>
            </a> 
            <a href="#" class="list-group-item">
                    <h4 class="list-group-item-heading">List group item heading</h4>
                    <p class="list-group-item-text">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam
                    eget risus varius blandit.</p>
            </a> </div>
        </div>
    </div>
            `;


        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="header-primary">
                            {title}
                            <CodeModal code={code} title={title} />
                        </h3>
                    </div>
                </div>
                <div>{ ReactHtmlParser(code) }</div>
            </div>
        )
    }
}

export default ListGroups;