import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class Headings extends Component {
    render() {
        let title = 'Headings';
        let code = `
            <div>
                <h1 class="header-primary">Heading 1 - Primary</h1>
                <h2 class="header-success">Heading 2 - Success</h2>
                <h3 class="header-info">Heading 3 - Info</h3>
                <h4 class="header-warning">Heading 4 - Warning</h4>
                <h5 class="header-danger">Heading 5 - Danger</h5>
                <h6>Heading 6 - Default</h6>
                <p class="lead">A paragraph tag with the class "lead" will emphasize it, and can be used as a sub-header.</p>
            </div>
            `;


        return (
            <div className="col-lg-6">
                <h3 className="header-primary">
                        {title}
                        <CodeModal code={code} title={title} />
                    </h3>
                    <div>{ ReactHtmlParser(code) }</div>
            </div>
        );
    }
}

export default Headings;