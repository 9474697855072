import React, { Component } from 'react';
import ListGroups from './ListGroups';
import Panels from './Panels';
import Wells from './Wells';

class Containers extends Component {
    render() {
        return (
            <div className="page-float">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-header">
                            <h1 id="containers" className="header-warning">Containers</h1>
                            <p className="lead">The main containers used in the CMS are panels and wells. Both are used to highlight or separate
                            content in some way. Panels can be given headers or footers and specific colors.</p>
                        </div>
                    </div>
                </div>
                <Panels />
                <Wells />
                <ListGroups />
            </div>
        )
    }
}

export default Containers;