import React, { Component } from 'react';
import CodeModal from '../CodeModal/CodeModal';
import ReactHtmlParser from 'react-html-parser';

class ButtonsSize extends Component {
    render() {
        let title = 'Button Sizes';
        let code = `
            <div>
                <button href="#" class="btn btn-primary btn-lg">Large button</button>
                <button href="#" class="btn btn-primary">Default button</button>
                <button href="#" class="btn btn-primary btn-sm">Small button</button>
                <button href="#" class="btn btn-primary btn-xs">Mini button</button>
                <button href="#" class="btn btn-default btn-xs"><i class="fas fa-columns"></i></button>
            </div>
            `;


        return (
            <div className="row">
                <div className="col-lg-12">
                    <h3 className="header-primary">
                        {title}
                        <CodeModal code={code} title={title} />
                    </h3>
                    <div>{ReactHtmlParser(code)}</div>
                </div>
            </div>
        )
    }
}

export default ButtonsSize;