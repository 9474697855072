import React, { Component } from 'react';
import Alerts from './Alerts';
import Labels from './Labels';
import Badges from './Badges';
import Navigation from './Navigation';

class Indicators extends Component {
    render() {
        return (
            <div className="page-float">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-header">
                            <h1 id="indicators" className="header-warning">Indicators</h1>
                            <p className="lead">Alerts are useful notices and can be used in any of the standard colors.
                            The steps indicator has three notable states: complete, current, and default, which indicated incomplete steps.</p>
                        </div>
                    </div>
                </div>
                <Alerts />
                <div className="row">
                    <Labels />
                    <Badges />
                    <Navigation />
                </div>
            </div>
        )
    }
}

export default Indicators;